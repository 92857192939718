import * as client_hooks from '../../../src/hooks.client.js';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50')
];

export const server_loads = [0,4,9,3];

export const dictionary = {
		"/(public)": [~32,[6]],
		"/(public)/about": [33,[6]],
		"/(app)/account": [~13,[2]],
		"/(public)/alternative/arena": [~34,[6,7]],
		"/(public)/apps": [~36,[6]],
		"/(public)/app": [~35,[6]],
		"/(app)/artifacts": [~14,[2]],
		"/(app)/artifacts/[id]": [~15,[2]],
		"/(app)/auth-app": [~21,[2,5]],
		"/(app)/auth/check-email": [~16,[2,4]],
		"/(app)/auth/forgot-pwd": [~17,[2,4]],
		"/(app)/auth/forgot-pwd/success": [18,[2,4]],
		"/(app)/auth/login": [~19,[2,4]],
		"/(app)/auth/signup": [~20,[2,4]],
		"/(public)/blog": [~37,[6,8]],
		"/(public)/blog/[slug]": [~38,[6,8]],
		"/(public)/changelog": [~39,[6]],
		"/(app)/collections": [~22,[2]],
		"/(app)/collections/[id]": [~23,[2]],
		"/(app)/collections/[id]/share": [~24,[2]],
		"/(public)/feedback": [~40,[6,9]],
		"/(public)/feedback/bugs": [~42,[6,9]],
		"/(public)/feedback/bugs/[...all]": [43,[6,9]],
		"/(public)/feedback/[...all]": [41,[6,9]],
		"/(app)/images": [~25,[2]],
		"/(app)/images/[id]": [~26,[2]],
		"/(public)/legal": [~44,[6,10]],
		"/(public)/legal/cookies": [~45,[6,10]],
		"/(public)/legal/fair-usage": [~46,[6,10]],
		"/(public)/legal/privacy": [~47,[6,10]],
		"/(public)/legal/terms": [~48,[6,10]],
		"/(public)/pricing": [~49,[6]],
		"/(app)/redeem-code": [~27,[2]],
		"/(public)/roadmap": [~50,[6]],
		"/(app)/set-password": [~28,[2]],
		"/(app)/shared/[key]": [~29,[2]],
		"/(app)/upgrade-limit": [~30,[2]],
		"/(app)/welcome": [31,[2]],
		"/(app)/[handle=profileHandle]": [~11,[2,3]],
		"/(app)/[handle=profileHandle]/collections": [~12,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';